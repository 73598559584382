import React from "react"
import { Layout } from "components/layouts/Layout/layout"
import { Script } from "gatsby"

const narvarsnippet = () => {
  return `<style type="text/css">
    #nvo_xz1_backgroundcontainer {
        background-color: rgba(255,255,255,0.85);
        max-width: 560px;
        margin: 0 auto;
    }

    @media only screen and (min-width : 960px) {
        #nvo_xz1_backgroundcontainer {
            background-color: rgba(255,255,255,0.85);
            max-width: 960px;
            margin: 0 auto;
        }
    }
</style>

<div style="
  background-image: url(https://storage.googleapis.com/shopify-zero-narvar/t0giwgyxduow7y57q15pesaz36kj);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
">
    <div id="nvo_xz1_backgroundcontainer"></div>
</div>`
}

//if not server side rendering

export default function ReturnPortal() {
  return (
    <>
      <Layout>
        <Script src="/narvar.js" />
        <h1 className="text-3xl font-bold text-center">Returns</h1>

        <div dangerouslySetInnerHTML={{ __html: narvarsnippet() }} />
      </Layout>
    </>
  )
}
